<template>
  <v-list-item link title="authentification" :to="{name: 'Authentificationyy'}">
    <v-list-item-icon class="d-flex justify-center">
      <v-icon color="white">mdi-lock-plus</v-icon>
    </v-list-item-icon>
    <v-list-item-content link>
      <v-list-item-title class="white--text">Authentification</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "MenuAuthentification",
  props: {
    superAdmin: Boolean
  }
}
</script>

<style scoped>

</style>